
<template>
  <div id="page-user-rdit">
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body text-center">
                    <div class="row">
                        <div class="col-lg-5">
                            <img :src="absoluteUrl(activeUser.image)" class="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />
                            <h4 class="mt-3 mb-0">{{activeUser.username}}</h4>
                            <p class="text-muted">Type: {{activeUser.role}}</p>
                        </div>
                        <div class="col-lg-7">
                            <div class="text-left">
                                <div class="table-responsive">
                                    <table class="table table-borderless table-sm">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Name :</th>
                                                <td class="text-muted">{{activeUser.first_name}} {{activeUser.last_name}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Phone :</th>
                                                <td class="text-muted">{{activeUser.phone}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email :</th>
                                                <td class="text-muted">{{activeUser.email}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Location :</th>
                                                <td class="text-muted">{{activeUser.country ? activeUser.country.name : ''}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <b-tabs content-class="mt-1" pills class="navtab-bg">
                        <b-tab>
                           <template v-slot:title>
                              <i class="mdi mdi-account-check mr-1"></i> Profile
                            </template>
                            <user-edit-tab-account />
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                              <i class="mdi mdi-notebook mr-1"></i> Info
                          </template>
                           <user-edit-tab-information />
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                              <i class="mdi mdi-lock-check mr-1"></i> Security
                          </template>
                          <user-edit-tab-password />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    }
  },
  created() {
    this.$store.dispatch("fetchActiveUser")
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        }
    })
  }
}

</script>
